import React, {useState, useEffect, useRef, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../styles/home.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { FiFacebook, FiChevronDown, FiInstagram, FiYoutube, FiPlay } from 'react-icons/fi';
import axios from 'axios';

import Modal from './Modal';
import { UserContext } from '../Contexts/UserContext';

import logo_black from '../files/logo_black.png';
import logo_white from '../files/logo_white.png';

import aboutUsPic from "../files/aboutUs.png";
import howWork from "../files/howWork.png";

import dataTest from './dataTest';

function BigVideoContainer({item, openVideoModal}){

  const { i18n } = useTranslation();
  const [thumbnail, setThumbnail] = useState('');
  const videoId = item.video_url.split('/').pop();

  const fetchThumbnail = async () => {
    try {
      const response = await axios.get(`https://vimeo.com/api/v2/video/${videoId}.json`);
      // console.log(response.data[0]);
      setThumbnail(response.data[0].thumbnail_large+".jpg");
    } catch (error) {
      console.error('Error fetching Vimeo thumbnail:', error);
    }
  };

  useEffect(() => {
    fetchThumbnail();
  }, []);

  return(
    <div className={styles.videoWrapper} >
      <div className={styles.videoImg_pc} onClick={()=> openVideoModal({en_title: item.en_title, ar_title: item.ar_title, id: videoId}) } >
        <div className={styles.BigVideo_pc} >
          <img src={thumbnail} style={{height: '100%', width: '100%', objectFit: "cover", borderRadius: 12}} />
          <div className={styles.bigVideoDarkOverlay} >
            <FiPlay color="#fff" size={36} />
          </div>
        </div>
      </div>
      <p className={styles.videoTitle_pc} >
        { i18n.language === 'en' ? item.en_title : item.ar_title }
      </p>
    </div>
  )
}
function SmallVideoContainer({item, openVideoModal}){

  const { i18n } = useTranslation();
  const [thumbnail, setThumbnail] = useState('');
  const videoId = item.video_url.split('/').pop();

  const fetchThumbnail = async () => {
    try {
      const response = await axios.get(`https://vimeo.com/api/v2/video/${videoId}.json`);
      // console.log(response.data[0]);
      setThumbnail(response.data[0].thumbnail_large+".jpg");
    } catch (error) {
      console.error('Error fetching Vimeo thumbnail:', error);
    }
  };

  useEffect(() => {
    fetchThumbnail();
  }, []);

  return(
    <div className={styles.videoWrapper} >
      <div className={styles.videoImg_pc} onClick={()=> openVideoModal({en_title: item.en_title, ar_title: item.ar_title, id: videoId}) } >
        <div className={styles.smallVideo_pc} >
          <img src={thumbnail} style={{height: '100%', width: '100%', objectFit: "cover", borderRadius: 12}} />
          <div className={styles.smallVideoDarkOverlay} >
            <FiPlay color="#fff" size={36} />
          </div>
        </div>
      </div>
      <p className={styles.videoTitle_pc} >
        { i18n.language === 'en' ? item.en_title : item.ar_title }
      </p>
    </div>
  )
}
function PhoneVideoContainer({item, openVideoModal}){
  const { i18n } = useTranslation();
  const [thumbnail, setThumbnail] = useState('');
  const videoId = item.video_url.split('/').pop();

  const fetchThumbnail = async () => {
    try {
      const response = await axios.get(`https://vimeo.com/api/v2/video/${videoId}.json`);
      // console.log(response.data[0]);
      setThumbnail(response.data[0].thumbnail_large+".jpg");
    } catch (error) {
      console.error('Error fetching Vimeo thumbnail:', error);
    }
  };

  useEffect(() => {
    fetchThumbnail();
  }, []);

  return(
    <div className={styles.videoWrapper} >
      <div className={styles.videoImg_phone} onClick={()=> openVideoModal({en_title: item.en_title, ar_title: item.ar_title, id: videoId}) } >
        <img src={thumbnail} style={{height: '100%', width: '100%', objectFit: "cover", borderRadius: 12}} />
        <div className={styles.smallVideoDarkOverlay} >
          <FiPlay color="#fff" size={36} />
        </div>
      </div>
      <p className={styles.videoTitle_phone} >
        { i18n.language === 'en' ? item.en_title : item.ar_title }
      </p>
    </div>
  )
}

export default function Home() {

  const { t, i18n } = useTranslation();
  const {apiUrl, siteId} = useContext(UserContext);

  const location = useLocation();
  const scrollContainerRef = useRef(null);
  const [videos, setVideos] = useState([]);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const translate = ()=>{
    if(i18n.language === 'en'){
      i18n.changeLanguage("ar");
    }else{
      i18n.changeLanguage("en");
    }
  }

  const openVideoModal = (item)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsVideoModalOpen(true);
    setModalData(item);
  }
  const closeVideoModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsVideoModalOpen(false);
    setModalData({});
  }

  const handleScrollLeft = () => {
    scrollContainerRef.current.scrollBy({ left: -274, behavior: 'smooth' });
  };

  const handleScrollRight = () => {
    scrollContainerRef.current.scrollBy({ left: 274, behavior: 'smooth' });
  };

  const getAllCategoriesForSite = async (siteId) => {
    try {
      const response = await axios.get(`${apiUrl}/categories/${siteId}`);

      // console.log(response.data)
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw new Error('Failed to fetch categories');
    }
  };

  const getLastVideos = async () => {
    try {
      const response = await axios.get(`${apiUrl}/last`, {
        params: { site: siteId },
      });
      setVideos(response.data);
    } catch (error) {
      console.log('There was an error fetching the videos: ' + (error.response ? error.response.data : error.message));
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    if(apiUrl){
      getLastVideos();
      getAllCategoriesForSite(siteId);
    }
  },[apiUrl, siteId]);

  return (
    <div className={styles.container} >
      <div className={styles.firstContainer} >
      <div className={styles.logoWrapper} >
          <img src={logo_white} style={{height: '100%', width: '100%', objectFit: "contain"}} />
        </div>
        <p className={styles.bigTitle} >{t('home.title')}</p>
        <FiChevronDown className={styles.iconAnimate} size={32} color='#fff' />
        {/*<div className={styles.LangWrapper} onClick={()=> translate() } >*/}
        {/*  <span>{i18n.language === 'en'? "ع" : "E"}</span>*/}
        {/*</div>*/}
      </div>
      <div className={styles.mainContainer} >
        {/* <div className={styles.mainCat} >
          <div className={styles.mainCatImgWrapper}>
            <img src={cat_1} style={{height: '100%', width: '100%', objectFit: "contain"}} />
          </div>
          <p className={styles.mainCatTitle} >{t('home.categories.beauty')}</p>
          <Link style={{textDecoration: 'none'}} to={`/${t('home.categories.beauty')}`} >
            <p className={styles.mainCatSubTitle} >Read more...</p>
          </Link>
        </div> */}
        { categories.length > 0 && <div className={styles.mainCat} >
          <div className={styles.mainCatImgWrapper}>
            <img src={categories[0].image} style={{height: '100%', width: '100%', objectFit: "contain"}} />
          </div>

         <Link style={{textDecoration: 'none'}} to={`/${categories[0].id}`} >
         <p className={styles.mainCatTitle} >
            {i18n.language === "en" ? categories[0].en_title : categories[0].ar_title}
          </p>
          </Link>
        </div>}

        {/* <div className={styles.catsWrapper} >
          <div className={styles.cat} >
            <img src={cat_2} style={{height: '250px', width: 'auto', objectFit: "contain"}} />
            <Link style={{textDecoration: 'none'}} to={`/${t('home.categories.career')}`} >
              <button className={styles.catBtn} >{t('home.categories.career')}</button>
            </Link>
          </div>
          <div className={styles.cat} >
            <img src={cat_3} style={{height: '250px', width: 'auto', objectFit: "contain"}} />
            <Link style={{textDecoration: 'none'}} to={`/${t('home.categories.pregnant')}`} >
              <button className={styles.catBtn} >{t('home.categories.pregnant')}</button>
            </Link>
          </div>
          <div className={styles.cat} >
            <img src={cat_4} style={{height: '250px', width: 'auto', objectFit: "contain"}} />
            <Link style={{textDecoration: 'none'}} to={`/${t('home.categories.personality')}`} >
              <button className={styles.catBtn} >{t('home.categories.personality')}</button>
            </Link>
          </div>
        </div> */}

        <div className={styles.catsWrapper} >
          {
            categories.length > 0 && categories.map((it, index)=> index > 0 &&
            <div key={it.id} className={styles.cat} >
              <img src={it.image} style={{height: '250px', width: 'auto', objectFit: "contain"}} />
              <Link style={{textDecoration: 'none'}} to={`/${it.id}`} >
                <button className={styles.catBtn} >
                  {i18n.language === "en" ? it.en_title : it.ar_title}
                </button>
              </Link>
            </div>
            )
          }
        </div>


        <div className={styles.secondTitleWrapper} >
          <p >{t('home.top_videos')}</p>
        </div>

        <div className={styles.videosWrapper_pc} ref={scrollContainerRef} >
          { videos.length > 0 && videos.map((it, index)=>{
            if(index % 2 === 0){
              return( <BigVideoContainer key={it.id} item={it} openVideoModal={openVideoModal} /> )
            }else{
              return( <SmallVideoContainer key={it.id} item={it} openVideoModal={openVideoModal} /> )
            }
          }) }
        </div>
        <div className={styles.videosWrapper_phone} >
          {videos.length > 0 && videos.map((it)=>
            <PhoneVideoContainer key={it.id} item={it} openVideoModal={openVideoModal} />
          ) }
        </div>

        <div className={styles.videosScrollBtns} >
          <button className={styles.videoScrollBtn} onClick={handleScrollLeft}>&lt;</button>
          <button className={styles.videoScrollBtn} onClick={handleScrollRight}>&gt;</button>
        </div>

        <div className={styles.footer} >
          <div className={styles.secondTitleWrapper} >
            <p >{t('home.who_are_we')}</p>
          </div>
          <div className={styles.footerContent} >
            <a className={styles.footerElement} href='https://taqniyat.sa' target='_blank' >
              <p className={styles.footerElementTitle} >{t('home.about')}</p>
              <div className={styles.footerElementIcon} >
                <img src={aboutUsPic} style={{height: '60%', width: '60%', objectFit: "contain"}} />
              </div>
            </a>
            {/* <div className={styles.footerElement} >
              <p className={styles.footerElementTitle} >{t('home.stay_in_touch')}</p>
              <div className={styles.socialNetworks} >
                <div className={styles.socialNetworkWrapper} >
                  <FiFacebook size={24} color='#fff' />
                </div>
                <div className={styles.socialNetworkWrapper} >
                  <FiInstagram size={24} color='#fff' />
                </div>
                <div className={styles.socialNetworkWrapper} >
                  <FiYoutube size={24} color='#fff' />
                </div>
              </div>
            </div> */}
            {/* <div className={styles.footerElement} >
              <p className={styles.footerElementTitle} >{t('home.how_it_works')}</p>
              <div className={styles.footerElementIcon} >
                <img src={howWork} style={{height: '60%', width: '60%', objectFit: "contain"}} />
              </div>
            </div> */}
          </div>
          <div className={styles.footerLogo} >
            <img src={logo_black} style={{height: '100%', width: '100%', objectFit: "contain"}} />
          </div>
          <p className={styles.copyright} >© 2023-2024. All right reserved. <span >Yoyo Mandarin</span> </p>
        </div>
      </div>
      <Modal isOpen={isVideoModalOpen} close={closeVideoModal} title={ i18n.language === "en" ? modalData.en_title : modalData.ar_title} >
        <div style={{
          width: '100%',
          height: window.innerWidth < 642 ? window.innerHeight*0.3 : window.innerHeight*0.5
        }} >
          <iframe
            src={`https://player.vimeo.com/video/${modalData.id}?byline=0&portrait=0&title=0&fullscreen=1`}
            title={modalData.en_title}
            style={{ width: '100%', height: '100%', border: 'none' }}
            frameborder="0"
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
            >
          </iframe>
        </div>
      </Modal>
    </div>
  )
}
